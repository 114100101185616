<!--
  Base Card 

  From Bootstrap: "A card is a flexible and extensible content container. It includes options for headers and footers, a wide variety of content, contextual background colors, and powerful display options."

  The MSS base card is a very light component.  Styling is part of the scss theming and most of the functionality
  is defaulted to the bootstrap-vue b-card component via the slot.

  The default title size is H3.  In the MSS designs, sometimes the title can be smaller.  In those cases, you can use
  the titleClass prop to pass in any of the header classes (h1, h2, h3, h4, h5, h6) to adjust the size.

  Example how to use:

  <base-card automation-id="base-card" title="Base Card" title-class="h4">
    <b-card-text>Some groovy text using b-card-text</b-card-text>
  </base-card>
  -->

<template>
	<BCard
		class="base-card"
		:data-test-automation-id="getAutomationId('card')"
		:data-tracking-id="getAutomationId('card')"
		v-bind="$attrs"
		:body-class="[
			{ 'no-top-bottom-pad': twoColumns },
			{ 'px-0': $slots.top },
			$attrs['body-class']
		]"
	>
		<BCardTitle v-if="title" :title-tag="titleTag" :class="titleClass">
			{{ title }}
		</BCardTitle>
		<!-- The sub-title-text-variant="" is used to remove the muted text class that is default in BSVue -->
		<BCardSubTitle
			v-if="subTitle"
			:sub-title-tag="subTitleTag"
			:class="['mb-2', subTitleClass]"
			sub-title-text-variant=""
		>
			{{ subTitle }}
		</BCardSubTitle>
		<BRow v-if="$slots['error-message']">
			<BCol class="error-container">
				<slot name="error-message" :automation-id="getAutomationId('card-error')"></slot>
			</BCol>
		</BRow>
		<template v-if="twoColumns">
			<BRow>
				<BCol cols="12" md="6" class="card-column card-left-column">
					<slot name="left"></slot>
				</BCol>
				<BCol cols="12" md="6" class="card-column card-right-column mt-3 mt-md-0">
					<slot name="right"></slot>
				</BCol>
			</BRow>
		</template>
		<template v-else-if="$slots.top">
			<div class="card-padding">
				<slot name="top"></slot>
			</div>
			<hr />
			<div class="card-padding">
				<slot name="bottom"><slot></slot></slot>
			</div>
		</template>
		<template v-else>
			<slot></slot>
		</template>
		<template v-if="$slots.footer" #footer>
			<slot name="footer"></slot>
		</template>
	</BCard>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BRow, BCol, BCard, BCardTitle, BCardSubTitle } from 'bootstrap-vue';

export default {
	name: 'BaseCard',
	components: {
		BRow,
		BCol,
		BCard,
		BCardTitle,
		BCardSubTitle
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		/** This overrides the BSVue title prop to allow us to place the value in the b-card-title component. */
		title: {
			type: String,
			default: null
		},
		/** Heading class to be applied to the card title.  Ex: h3, h4, etc... */
		titleClass: {
			type: String,
			default: null
		},
		titleTag: {
			type: String,
			default: 'p'
		},
		subTitle: {
			type: String,
			default: null
		},
		subTitleClass: {
			type: String,
			default: null
		},
		subTitleTag: {
			type: String,
			default: 'p'
		},
		twoColumns: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
.base-card {
	margin-bottom: 1.5rem;
}
.card img {
	object-fit: cover;
}
.card-footer {
	border-top: 1px solid $gray-light;
}
.card-padding {
	padding-left: 30px;
	padding-right: 30px;
}
.error-container {
	margin: 30px 0 0 0;
}
.no-top-bottom-pad {
	padding-top: 0;
	padding-bottom: 0;
	& .card-column {
		padding-top: 10px;
		padding-bottom: 10px;
		@include media-breakpoint-up(md) {
			padding-top: 20px; // More padding on larger screens.
			padding-bottom: 20px;
		}
	}
	& .card-left-column {
		border-right: 1px solid $gray-light;
		@include media-breakpoint-down(sm) {
			border-right: none;
			border-bottom: 1px solid $gray-light;
		}
		@include media-breakpoint-up(md) {
			padding-right: 30px;
		}
	}
	& .card-right-column {
		@include media-breakpoint-up(md) {
			padding-left: 30px;
		}
	}
}
</style>
